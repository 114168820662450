<template>
    <div>
        <div @click="toggleModal" class="btn btn-primary">Выбрать точку на карте</div>
        <div v-if="modal" class="modal-overlay">
            <div class="modal-header">
                <span>Отметьте точку проекта на карте</span>
                <span class="btn btn-link" @click="toggleModal">Закрыть</span>
            </div>

            <div>
                <div
                    ref="img"
                    class="plan"
                    @click="setLabelByClick"
                    :style="{ backgroundImage: 'url(/img/render2.jpg)' }">
                    <div :class="['main-slide-dot', {'main-slide-dot_current': current}]"
                         v-for="({ current, style }) in markers"
                         :style="style"
                    />
                </div>
            </div>

            <div class="modal-footer">
                <div v-show="success" class="text-success mr-4">
                    {{ success }}
                </div>
                <button class="btn btn-primary" @click="save" type="button">Сохранить</button>
                <button class="btn btn-danger" @click="reset" type="button">Сбросить</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            projectId: {
                type: String,
                required: false,
                default: null,
            },
            labels: {
                type: Array,
                required: false,
                default: []
            }
        },
        data: () => ({
            modal: false,
            success: null,
            currentMarker: {
                x: null,
                y: null,
            }
        }),
        computed: {
            markers() {
                let markers = [];

                this.labels.map(({label, id}) => {
                    const coordinates = JSON.parse(label);
                    const {x: cx, y: cy} = this.currentMarker;

                    const {x = 50, y = 50} = coordinates || {};
                    const current = +this.projectId === +id;


                    const style = {
                        display: 'block',
                        top: `${y}%`, left: `${x}%`
                    };

                    markers.push({
                        id,
                        current,
                        style: current ? {...style, top: `${cy ? cy : y}%`, left: `${cx ? cx : x}%`} : style
                    })
                });

                return markers;
            }
        },
        methods: {
            toggleModal() {
                this.modal = !this.modal;

                if (this.modal) {
                    document.documentElement.style.overflow = 'hidden';
                } else {
                    document.documentElement.style.overflow = 'auto';
                }
            },
            setLabelByClick(event) {
                const height = this.$refs.img.clientHeight;
                const width = this.$refs.img.clientWidth;

                let x = event.offsetX;
                x = parseInt(x / width * 100, 10);
                x = x < 0 ? 0 : x;
                x = x > 100 ? 100 : x;

                let y = event.offsetY;
                y = parseInt(y / height * 100, 10);
                y = y < 0 ? 0 : y;
                y = y > 100 ? 100 : y;

                console.log("POINT DEBUG: ", x + '% ' + y + '%');

                console.log(

                )
                this.currentMarker = {x, y}
            },
            save() {
                const {projectId, currentMarker} = this;

                if (currentMarker && currentMarker.x && currentMarker.y) {
                    window.axios.post(`/admin/project/${projectId}/marker`, {
                        id: projectId,
                        label: currentMarker
                    }).then(({data}) => {
                        if (data) {
                            this.success = data;

                            setTimeout(() => {
                                this.success = null;
                            }, 3000)
                        }
                    });
                }
            },
            reset() {
                this.currentMarker = {
                    x: null,
                    y: null,
                };
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-overlay {
        position: fixed;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: #ffffff;
        z-index: 999;
        opacity: 1;
    }

    .modal-header {
        align-items: center;
    }

    .plan {
        height: 44vw;
        background-position: center;
        background-size: 100%;
        max-height: 840px;
        background-repeat: no-repeat;
        position: relative;
    }
</style>
