(function( $ ){

  $.fn.filemanager = function(type, options) {
    type = type || 'file';
    var callback = options !== undefined && options.callback ? options.callback : '';

    this.on('click', function(e) {
      var route_prefix = (options && options.prefix) ? options.prefix : '/admin/filemanager';
      localStorage.setItem('target_input', $(this).data('input'));
      localStorage.setItem('target_preview', $(this).data('preview'));

      if($(this).data('go-to') !== undefined) {
          localStorage.setItem('filemanagerFolderTMP', $(this).data('go-to'));
      }

      window.open(route_prefix + '?type=' + type + '&multipleCallback=' + callback, 'FileManager', 'width=900,height=600');
      window.SetUrl = function (url, file_path) {
          //set the value of the desired input to image url
          var target_input = $('#' + localStorage.getItem('target_input'));
          target_input.val(file_path).trigger('change');

          //set or change the preview image src
          var target_preview = $('#' + localStorage.getItem('target_preview'));
          target_preview.attr('src', url).trigger('change');
      };
      return false;
    });

    window.setMultiple = function (string) {
        var params = JSON.parse(string),
            html = '<div class="d-flex flex-wrap multiple-item ">';
        for (var i = 0; params.length > i; i++) {
            if(!i) {
                SetUrl(params[i].url, params[i].file_path);
            } else {
                html += '<div class="col-2"><img class="img-fluid" src="'+params[i].file_path+'">' +
                    '<input type="hidden" value="'+params[i].file_path+'" /></div>'
            }
        }
        html += '</div>';

        $('#' + localStorage.getItem('target_preview')).parent().after(html);
    }
  }

})(jQuery);
